// きりたん丼共通

/* ファボを肉球に */
.fa-star::before {
  font-size: large;
  content: '\f1b0';
}

.compose-form__publish-button-wrapper > .button--block {
  margin: 0;
}

.compose-form__publish .button--block {
  margin: 1px;
}

.compose-form__submit {
  .button {
    border: 1px solid $highlight-text-color;
  }

  .icon-button-kiri {
    max-width: 28px;
  }
}

.floating-action-button {
  width: 3rem;
  height: 3rem;
  bottom: 5.25rem;
  right: auto;
}

.floating-toot-area {
  position: fixed;
  display: flex;
  width: 99%;
  bottom: 1.65rem;
  align-items: center;

  .toot__input {
    flex: auto;
    min-height: 2.625rem;
    font-size: 1.1rem;
    white-space: pre-wrap;
    margin: 2px;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    border: 2px solid #333;
    border-radius: 10px;
    padding: 4px;
  }

  .icon-button-kiri {
    flex: none;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 25%;
    color: $highlight-text-color;
    border: 1px solid $highlight-text-color;
  }
}

.status {
  padding: 4px;
  padding-left: 12px;
  border-bottom: 2px solid lighten($ui-base-color, 16%);
}

.status__action-bar {
  margin-top: 4px;
}

/* box header */
.column,
.drawer {
  flex: 1 1 auto;
  min-width: 320px;
  max-width: 600px;
  padding: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.drawer__header {
  border-top-left-radius: 10px 10px;
}

.column-header {
  border-top-left-radius: 10px 10px;
}

.drawer__inner {
  border-bottom-right-radius: 20px 20px;
}

/* box scroll */
.column > .scrollable {
  border-bottom-right-radius: 20px 20px;
}

/* toot option icon */
.compose-form__buttons {
  border-radius: 10px;
}

// .compose-form__publish-button-wrapper > .button {
//   border-radius: 10px ;
// }

/* textarea */
.spoiler-input__input {
  border-radius: 20px;
}

.autosuggest-textarea__textarea {
  border-radius: 20px;
}

/* reply */
.reply-indicator {
  border-radius: 4px;
  margin-bottom: 15px;
}

.getting-started,
.getting-started__trends {
  border-bottom-right-radius: 20px 20px;
}

.getting-started__trends {
  margin-top: 24px;
}

.table td,
.table th {
  white-space: pre-wrap;
}

.notification-group {
  padding: 6px;

  &__icon {
    width: 32px;

    .icon {
      width: 24px;
      height: 24px;
    }
  }
}

.announcements__kiriwrapper {
  margin: 3px;
  border-radius: 10px;
  border: solid 2px $ui-base-lighter-color;

  .column-header__button {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .announcements {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .announcements__pagination > span {
    font-size: 16px;
    vertical-align: super;
  }

  .column-header__button.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .fa-fw.fa-bullhorn {
    font-size: x-large;
  }
}

.announcements__item__range {
  font-weight: bold;
}

.column-header__button {
  font-size: x-large;
  width: 100%;
  text-align: left;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.status__action-bar-button.icon-button.active .fa.fa-fw.fa-star {
  animation: blink 0.9s ease infinite;
}

/* 非公開色分け */
.status-private {
  background: rgba(180, 55, 132, 20%);
}

/* 未収載色分け */
.status-unlisted {
  background: rgba(36, 190, 56, 20%);
}

/* ダイレクト */
.status.status-direct {
  background: rgba(20, 60, 255, 20%);
}

.status.status-direct:not(.read) {
  background: rgba(20, 60, 255, 40%);
}

// 非公開・DMマーク点滅
.status__visibility-icon {
  .fa-lock {
    color: #d0d662;
    animation: blink 1.3s ease infinite;
  }

  .fa-envelope {
    color: $white;
    animation: blink 1.3s ease infinite;
  }
}

/* ローカル限定 */
.status.status-local-only::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-left: solid 4px rgba($success-green, 0.7);
  pointer-events: none;
}

.notification,
.status__wrapper {
  &.unread {
    &::before {
      border-left: 4px solid $highlight-text-color;
    }
  }
}

.emoji-mart-scroll {
  height: 500px;
  max-height: 90vh;
}

/* ユーザ絵文字拡大  */
.reply-indicator__content,
.announcements__item__content,
.status__content,
.account__header__tabs__name,
.display-name,
.status__wrapper,
.permalink,
.autosuggest-emoji {
  .emojione {
    width: auto !important;
    max-width: 4em !important;
    height: 1.8em !important;
    margin: 0 !important;
  }
}

.compose-form .autosuggest-account-icon {
  margin-right: 4px;
  width: 26px;
  height: 26px;
}

.drawer__inner__mastodon > img {
  object-position: center bottom;
  width: 100%;
}

// .compose-form__local-only {
//   margin-left: 4px;
//   margin-top: 2px;
// }

@keyframes rubber-band {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubber-band {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.rubberband {
  display: inline-block;
  -webkit-animation: rubber-band 1.1s ease infinite;
  animation: rubber-band 1.1s ease infinite;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }

  33% {
    -webkit-transform: rotate3d(0, 0, 1, 120deg);
    transform: rotate3d(0, 0, 1, 120deg);
  }

  66% {
    -webkit-transform: rotate3d(0, 0, 1, 240deg);
    transform: rotate3d(0, 0, 1, 240deg);
  }

  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }

  33% {
    -webkit-transform: rotate3d(0, 0, 1, 120deg);
    transform: rotate3d(0, 0, 1, 120deg);
  }

  66% {
    -webkit-transform: rotate3d(0, 0, 1, 240deg);
    transform: rotate3d(0, 0, 1, 240deg);
  }

  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

.spin {
  display: inline-block;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 6s linear infinite;
}

@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  70% {
    -webkit-transform: translate3d(0, -8px, 0);
    transform: translate3d(0, -8px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  70% {
    -webkit-transform: translate3d(0, -8px, 0);
    transform: translate3d(0, -8px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }
}

.jump {
  display: inline-block;
  -webkit-animation: bounce 1.1s ease infinite;
  animation: bounce 1.1s ease infinite;
}

.flip {
  display: inline-block;
  -ms-filter: 'FlipH';
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
}

.flip-v {
  display: inline-block;
  -ms-filter: 'FlipV';
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
}

.rotate90 {
  display: inline-block;
  transform: rotate(90deg);
}
